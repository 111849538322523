/** @jsx jsx */
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { arrayOf, bool, number, oneOf, string } from 'prop-types'
import { Container, Heading, jsx } from 'theme-ui'

import ButtonList from '~/components/ButtonList'
import IntroTransition from '~/components/IntroTransition'
import Media from '~/components/Media'
import renderLink from '~/content/renderLink'
import renderRichText from '~/content/renderRichText'
import {
  ContentfulImagePropType,
  ContentfulImageType,
  ContentfulRichTextPropType,
  ContentfulRichTextType,
  LinkPropType,
  LinkType,
  MediaPropType,
  MediaType,
} from '~/types'
import Section from '~/components/Section'

const DISPLAY_NAME = 'ImageCopySection'

const ALIGN_FLEX_MAP = {
  Bottom: 'flex-end',
  Center: 'center',
  Left: 'flex-start',
  Right: 'flex-end',
  Top: 'flex-start',
}

const PROP_TYPES = {
  body: ContentfulRichTextPropType.isRequired,
  elementId: string,
  heading: string.isRequired,
  image: ContentfulImagePropType,
  imageHorizontalAlign: oneOf(['Left', 'Center', 'Right']),
  imageMaxWidth: number,
  imageVerticalAlign: oneOf(['Top', 'Center', 'Bottom']),
  invertLayout: bool,
  links: arrayOf(LinkPropType),
  media: MediaPropType,
}

interface PropTypes {
  body: ContentfulRichTextType,
  elementId?: string,
  heading: string,
  image: ContentfulImageType,
  imageHorizontalAlign: 'Left' | 'Center' | 'Start',
  imageMaxWidth: number,
  imageVerticalAlign: 'Top' | 'Center' | 'Bottom',
  invertLayout: boolean,
  links: LinkType[],
  media: MediaType,
}

const Component = ({
  body,
  elementId,
  heading,
  image,
  imageHorizontalAlign,
  imageMaxWidth,
  imageVerticalAlign,
  invertLayout,
  links,
  media,
}: PropTypes) => (
  <Section id={elementId}>
    <Container>
      <IntroTransition>
        <div
          sx={{
            display: 'grid',
            gridTemplateAreas: [
              '"copy" "image"',
              null,
              invertLayout ? '"image copy"' : '"copy image"',
            ],
            gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
            gap: 4,
            alignItems: 'center',
          }}
        >
          <div sx={{ gridArea: 'copy' }}>
            <Heading
              sx={{
                variant: 'text.heading1',
                marginBottom: 1,
              }}
            >
              {heading}
            </Heading>
            {renderRichText(body, { style: 'prospect' })}
            {links?.length && (
              <ButtonList
                sx={{
                  marginTop: 2,
                  justifyContent: 'flex-start',
                }}
              >
                {links?.map(({ id, ...link }) => (
                  <li key={id}>
                    {renderLink(link)}
                  </li>
                ))}
              </ButtonList>
            )}
          </div>
          <div
            sx={{
              gridArea: 'image',
              alignSelf: 'stretch',
              display: 'flex',
              alignItems: ALIGN_FLEX_MAP[imageHorizontalAlign] ?? 'center',
              justifyContent: ALIGN_FLEX_MAP[imageVerticalAlign] ?? 'center',
            }}
          >
            {media ? (
              <Media media={media} />
            ) : (
              <GatsbyImage
                alt={image.title}
                draggable={false}
                image={getImage(image.localFile)}
                sx={{
                  maxWidth: imageMaxWidth,
                  width: '100%',
                }}
              />
            )}
          </div>
        </div>
      </IntroTransition>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
